// This is included with the Parsley library itself,
// thus there is no use in adding it to your project.


Parsley.addMessages('en', {
  defaultMessage: "This value seems to be invalid.",
  type: {
    email:        "Không đúng định dạng email.",
    url:          "Không đúng định dạng url.",
    number:       "Không đúng định dạng số.",
    integer:      "Không đúng định dạng integer.",
    digits:       "Không đúng định dạng digits.",
    alphanum:     "Không đúng định dạng alphanumeric."
  },
  notblank:       "Xin vui lòng điền thông tin.",
  required:       "Xin vui lòng điền thông tin.",
  pattern:        "Thông tin nhập vào không đúng định dạng.",
  min:            "Mật khẩu yêu cầu tối thiểu %s ký tự.",
  max:            "This value should be lower than or equal to %s.",
  range:          "This value should be between %s and %s.",
  minlength:      "Mật khẩu quá ngắn. Mật khẩu yêu cầu tối thiểu %s ký tự.",
  maxlength:      "This value is too long. It should have %s characters or fewer.",
  length:         "This value length is invalid. It should be between %s and %s characters long.",
  mincheck:       "You must select at least %s choices.",
  maxcheck:       "You must select %s choices or fewer.",
  check:          "You must select between %s and %s choices.",
  equalto:        "Mật khẩu nhắc lại không trùng với mật khẩu mới."
});

Parsley.setLocale('en');
